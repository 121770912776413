/*!

 =========================================================
 * Puliservicios - v1.0.0
 =========================================================

 * Creator Page: https://oscarbeses.com
 * Template Page: http://www.creative-tim.com/product/paper-kit-2
 * Copyright 2020 Oscar Beses (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/OscarBeses/puliservicios/blob/master/LICENSE)

 =========================================================

 */

@import "paper-kit/variables";
@import "paper-kit/mixins";

@import "paper-kit/typography";

// Core CSS
@import "paper-kit/misc";
@import "paper-kit/buttons";
@import "paper-kit/inputs";
@import "paper-kit/progress-bars";
@import "paper-kit/sliders";
@import "paper-kit/alerts";
@import "paper-kit/labels";
@import "paper-kit/tooltips-and-popovers";
@import "paper-kit/sections";
@import "paper-kit/checkbox-radio";
@import "paper-kit/navbars";
@import "paper-kit/images";
@import "paper-kit/cards";
@import "paper-kit/footers";


// Bootstrap Switch
@import "paper-kit/bootstrap-switch";

// Fancy Stuff
@import "paper-kit/dropdown";
//
// icons
@import "paper-kit/icons";
//
@import "paper-kit/tabs-navs-pagination";
@import "paper-kit/collapse";
@import "paper-kit/carousel";
@import "paper-kit/modal";
//
@import "paper-kit/responsive";
@import "paper-kit/plugins/datetimepicker";
@import "paper-kit/social-buttons";
@import "paper-kit/examples";
