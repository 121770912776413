.landing-alert {
  margin-bottom: 0;
}

.landing-section {
  padding: 100px 0;
  z-index: 1;
}
.section-buttons {
  z-index: 1;
}
.landing-section .btn-simple {
  padding: 0;
}
.landing-section .column {
  padding: 0 75px 0 25px;
}
.team-player .img-circle,
.team-player .img-thumbnail {
  display: block;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
.contact-form {
  margin-top: 30px;
}
.contact-form label {
  margin-top: 15px;
}
.contact-form .btn {
  margin-top: 30px;
}

.navbar-relative {
  position: relative;
  z-index: 2;
}
#register-navbar a {
  color: #fff;
}
// .register-background .container {
//   margin-top: 11%;
//   position: relative;
//   z-index: 3;
// }
.register-footer {
  bottom: 20px;
  position: absolute;
  z-index: 1;
  width: 100%;
  background: transparent;
  color: $white-color;
  left: 0;
}
.register-footer .fa-heart {
  color: #eb5e28;
}

.register-card label {
  margin-top: 15px;
}
.register-card .title {
  color: #b33c12;
  text-align: center;
}
.register-card .btn {
  margin-top: 30px;
}
.register-card .forgot {
  text-align: center;
}

.profile-content {
  position: relative;
}
.owner {
  text-align: center;
}
.owner .avatar {
  padding: 15px;
  max-width: 180px;
  margin: -85px auto 0;
  display: inline-block;
}
.owner .name h4 {
  margin-top: 10px;
}
.profile-tabs {
  margin: 50px 0;
  min-height: 300px;
}
#following h3 {
  margin: 20px 0 40px 0;
}
#follows .follows .unfollow .checkbox {
  margin-top: -15px;
}
#follows .follows h6 {
  margin-top: 15px;
}
.follows hr {
  margin-top: 10px;
}

.category-absolute {
  position: absolute;
  text-align: center;
  top: 100vh;
  margin-top: -60px;
  padding: 0 15px;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);

  .creative-tim-logo {
    max-width: 140px;
    top: -2px;
    left: 3px;
    position: relative;
  }
}

.following {
  img {
    max-width: 70px;
  }
}

