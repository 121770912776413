.carousel-control-prev,
.carousel-control-next{
    background-color: $social-facebook;
    border-radius: 50%;
    opacity: 1;
    text-shadow: none;
    font-size: 12px;

    &:hover,
    &:focus{
        opacity: 1;
        background-color: #294F6D;
    }
}

.carousel-control-prev-icon{
    background-image: none !important;
    margin-top: -5px;
    &:before{
        color: #b4d7f1;
        font-size: 26px;
        margin: 2px;
        padding-right: 3px;
        font: normal normal normal 25px/1 FontAwesome !important;
        content: "\f104" !important;
    }
}
.carousel-control-next-icon{
    background-image: none !important;
    margin-top: -5px;
    &:before{
        color: #b4d7f1;
        font-size: 26px;
        margin: 2px;
        padding-left: 2px;
        font: normal normal normal 25px/1 FontAwesome !important;
        content: "\f105" !important;
    }
}
.carousel-control-prev{
    height: 30px;
    top: 48%;
    width: 30px;
    left: 20px;
    opacity: 0;
}
.carousel-control-next{
    height: 30px;
    right: 20px;
    top: 48%;
    width: 30px;
    opacity: 0;
}
.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .fa, .carousel-control .fa {
    display: inline-block;
    z-index: 5;
}
.carousel-control-prev .fa{
    font-size: 26px;
    margin: 2px;
    padding-right: 3px;
}
.carousel-control-next .fa{
    font-size: 26px;
    margin: 2px;
    padding-left: 3px;
}
.carousel-control-prev, .carousel-control-next {
    background-image: none;
}

.page-carousel{
    border: none !important;
}

.page-carousel:hover{
    .carousel-control-prev,
    .carousel-control-next{
        opacity: 1;
        @include transition(300ms, linear);
    }
}
ngb-carousel{
    &:focus{
        outline: none !important;
    }

}
